<template>
  <div>
    <div
      :style="{
        position: 'fixed',
        top: '0px',
        width: '100vw',
        height: '100px',
        background: `linear-gradient(180deg, ${
          $vuetify.theme.dark ? '#272727' : getThemeMode.appBarColor
        } -0%, rgba(0, 0, 0, 0) 100%)`,
        zIndex: 5,
      }"
    />
    <v-app-bar
      :color="$vuetify.theme.dark ? '#272727' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm ma-4 rounded-lg"
      flat
      height="65"
    >
      <v-app-bar-nav-icon
        v-if="!!selectedOrganization"
        v-ripple="{ class: 'primary--text' }"
        @click="toggleVerticalSidebarDrawer"
      />
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />
      <v-btn
        v-if="!!selectedOrganization"
        @click="openSpotlight"
        plan
        elevation="0"
        small
        rounded
        class="ml-2"
      >
        <v-icon small left>mdi-help-circle-outline</v-icon>
        Ajuda
        <div v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
          <code>ctrl</code> + <code>;</code>
        </div>
      </v-btn>
      <v-chip small disabled v-else> Selecione uma organização </v-chip>

      <v-spacer />

      <!-- <v-badge
        bordered
        overlap
        content="3"
        color="red"
        offset-x="22"
        offset-y="22"
      >
        <v-btn icon @click="notificationDrawer = !notificationDrawer">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge> -->

      <v-chip
        pill
        class="transparent rounded-pill py-6"
        @click="userDrawer = !userDrawer"
      >
        Olá, {{ userFirstName }}
        <base-avatar :seed="user.id" :src="user.photo" class="ml-2" />
      </v-chip>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
      style="z-index: 50"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <v-switch
          v-if="inPwa"
            v-model="initOnAdmin"
            label="Iniciar no painel da organização" 
            class="mb-2"
          />
          <v-btn @click="toggleDarkMode" block class="shadow-sm mb-2" small>
            <v-icon class="mr-2" small>{{
              $vuetify.theme.dark
                ? "mdi-lightbulb-on-outline"
                : "mdi-moon-waning-crescent"
            }}</v-icon>
            {{ $vuetify.theme.dark ? "Modo claro" : "Modo escuro" }}
          </v-btn>
          <base-hover-button
            @click.native="backToApp"
            text="Voltar ao App"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-redo"
            class="mb-2"
          />
          <base-hover-button
            @click.native="logoutUser"
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <!-- <v-navigation-drawer
      v-model="notificationDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <notification-drawer>
        <template v-slot:notificationDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="notificationDrawer = !notificationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="View All Notifications"
            block
            bg-color="primary lighten-5 primary--text"
          />
        </div>
      </template>
    </v-navigation-drawer> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
    NotificationDrawer: () => import("../common-drawer/NotificationDrawer.vue"),
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapGetters("auth", ["user", "userFirstName"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    initOnAdmin: {
      get() {
        return window.localStorage.getItem("initOnAdmin") === "true";
      },
      set(value) {
        window.localStorage.setItem("initOnAdmin", value);
      },
    }
  },
  data() {
    return {
      userDrawer: false,
      // notificationDrawer: false,
    };
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions(["changeThemeDarkMode"]),
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.changeThemeDarkMode(this.$vuetify.theme.dark);
    },
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();
    },
    backToApp() {
      this.$router.push({ name: "app.index" });
    },
    openSpotlight() {
      this.$root.$emit("spotlight:open");
    },
    logoutUser() {
      this.logout();

      this.$router.push("/auth");
    },
  },
};
</script>

<style lang="scss">
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
